import BCALogo from './BCALogo.png'
import BRILogo from './BRILogo.png'
import MandiriLogo from './MandiriLogo.png'
import BNILogo from './BNILogo.png'
import SahabatSampoernaLogo from './BankSampoerna.png'
import PermataLogo from './Permata.png'
import CimbLogo from './CIMB.svg'
import BSILogo from './BSI.png'
import DBSLogo from './DBS.svg'
import BJBLogo from './BJB.png'

export { BCALogo, BRILogo, MandiriLogo, SahabatSampoernaLogo, BNILogo, PermataLogo, CimbLogo, DBSLogo, BSILogo, BJBLogo }