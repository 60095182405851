import AppLogo from './OrderingLong.svg';
import CircleBottom from './circle_bottom.svg';
import CircleRight from './circle_right.svg';
import MainBackground from './main_bg.svg';
import ProductSample from './product_sample.png';
import ProductCartImage from './product_cart.png';
import QrisImage from './qris.png';

export {
  AppLogo,
  CircleBottom,
  CircleRight,
  MainBackground,
  ProductSample,
  ProductCartImage,
  QrisImage,
};
