import MailIcon from './mail_ic.svg';
import LockIcon from './lock_ic.svg';
import HomeIcon from './home_ic.svg';
import TodoListIcon from './todo_ic.svg';
import QRCodeIcon from './qrcode_ic.svg';
import UserIcon from './user_ic.svg';
import ArrowLeftIcon from './arrow_left_ic.svg';
import SearchIcon from './search_ic.svg';
import CloseFillIcon from './close_fill_black_ic.svg';
import CloseYellowIcon from './close_fill_yellow_ic.svg';
import PlusIcon from './plus_ic.svg';
import PlusIconBlack from './plus_ic_black.svg';
import MinusIcon from './minus_ic.svg';
import TutupKasirIcon from './tutupKasir.svg';
import CancelIcon from './cancelIcon.svg';
import PaidIcon from './paidIcon.svg';
import OrderIcon from './orderIcon.svg';
import WaitingIcon from './waitingPaymentIcon.svg';
import CartIcon from './cart_ic.svg';
import MiniTimesGrayIcon from './small_x_ic.svg';
import YellowCircleCheckIcon from './circle_check_yellow_ic.svg';
import BillConfirmationIcon from './bill_question_ic.svg';
import CircleQuestIcon from './circle_quest_ic.svg';
import ArrowDownIcon from './arrow_down.svg';
import ApprovalOrderIcon from './icon_order.svg';
import PlusCircle from './plus-circle.svg';

//profile
import ProfileIcon from './profileIcon.svg';
import UbahPassword from './Ubah-Password.svg';
import UbahProfile from './Ubah-Profile.svg';
import KeluarIcon from './icon-keluar.svg';
import KembaliIcon from './kembali_ic.svg';
import CloseIcon from './ic_close_out.png';

export {
  MailIcon,
  LockIcon,
  HomeIcon,
  CloseIcon,
  TodoListIcon,
  QRCodeIcon,
  UserIcon,
  ArrowLeftIcon,
  SearchIcon,
  CloseFillIcon,
  PlusIcon,
  MinusIcon,
  CloseYellowIcon,
  TutupKasirIcon,
  MiniTimesGrayIcon,
  YellowCircleCheckIcon,
  BillConfirmationIcon,
  CancelIcon,
  PaidIcon,
  WaitingIcon,
  OrderIcon,
  CartIcon,
  ProfileIcon,
  KeluarIcon,
  UbahPassword,
  UbahProfile,
  KembaliIcon,
  CircleQuestIcon,
  ArrowDownIcon,
  ApprovalOrderIcon,
  PlusIconBlack,
  PlusCircle,
};
